import { Dispatch } from 'react'

import { ActiveChallenge, Hit, Player, WorkoutData } from './workout'

export enum ButtonEnum {
  primary = 'button--primary',
  secondary = 'button--secondary',
  tertiary = 'button--tertiary',
  disabled = 'button--disabled',
}

export interface ButtonProps {
  modifier?: ButtonEnum
  onClick?: (evt: React.MouseEvent, ...rest: any) => void
  children: React.ReactElement | React.ReactNode | string
  className?: string
}

export interface TextProps {
  className?: string
  children: React.ReactNode
}

export enum IconNames {
  challenge = 'challenge',
  pause = 'pause',
  reset = 'reset',
}

export interface IconProps {
  name: IconNames
  size?: number
  onClick: () => void
}

export interface CardsGridProps {
  workout: WorkoutData
  totalHits: Hit[]
  activePlayer: Player
}

export interface ChallengeCardProps {
  number: number
  activeChallenge: ActiveChallenge
  hitsCount?: number
  totalForce?: number
}

export interface WorkoutCardProps {
  number: number
  hitsCount?: number
  avgSpeed?: number
  totalForce?: number
  avgIntensity?: number
}

export interface AvgCardProps {
  totalHitsPerMinute?: number
  avgSpeedPerMinute?: number
  totalForceInKg?: number
  avgIntensity?: number
}

export interface ModalProps {
  isModalOpen: boolean
  setModalOpen: Dispatch<React.SetStateAction<boolean>>
  children: React.ReactNode | JSX.Element
}

export interface ToolbarProps {
  toggleActivePlayer: () => void
  resetWorkoutData: () => void
}
