import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import App from './App'

const apiUrl = process.env.REACT_APP_API_URL || 'api.test.nextround.madx.nl'
let apiKey = localStorage.getItem('NEXTROUND_GROUP_API')
let apiKeyString: null | string = null

if (apiKey && JSON.parse(apiKey)) {
  apiKey = JSON.parse(apiKey)
  apiKeyString = apiKey?.toLowerCase().startsWith('basic')
    ? apiKey
    : `Basic ${apiKey}`
} else {
  apiKey = null
}

const wsLink = new WebSocketLink({
  uri: `wss://${apiUrl}/graphql`,
  options: {
    reconnect: true,
    connectionParams: () => ({
      Authorization: apiKeyString,
      'X-Authorization-Method': 'api',
    }),
  },
})

const httpLink = createHttpLink({
  uri: `https://${apiUrl}/graphql`,
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: apiKeyString,
      'X-Authorization-Method': 'api',
    },
  }
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  authLink.concat(wsLink),
  authLink.concat(httpLink)
)

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({ addTypename: false }),
})

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
