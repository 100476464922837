import { gql } from '@apollo/client'

// export const CREATE_WORKOUT = gql`
//     mutation createWorkoutGroup($workouts: [WorkoutInputSchema!] {
//         createWorkoutGroup(input: {workouts: $workouts}) {
//             id
//         }
//     })
// `

// TODO : Update the mutation with dynamic variables
export const SET_BAG = gql`
  mutation setBag {
    updateTablet(
      input: {
        id: "fd6fef86-3975-4e4f-a0fe-e762ce408ffd"
        bag: { id: "5558ed39-3789-45dc-97e3-d4bd9dde6888" }
      }
    ) {
      id
      bag {
        id
      }
    }
  }
`

export const PUNCH = gql`
  mutation punch($bagId: String!) {
    punch(bagId: $bagId) {
      angle
    }
  }
`