import React, { createContext, useContext, useState } from 'react'

import { Config } from '../interfaces/providers'
import { initConfigContext } from '../constants/providers'

// This provider handles settings like:
// - toggle the displaying of force, speed, intensity, hits
// - timer
// - sound on / off
// - possibly other settings; depending on design

const ConfigContext: React.Context<Config> = createContext(initConfigContext)

export const ConfigProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [displayHits, setDisplayHits] = useState<boolean>(true)
  const [displayForce, setDisplayForce] = useState<boolean>(true)
  const [displaySpeed, setDisplaySpeed] = useState<boolean>(true)
  const [displayIntensity, setDisplayIntensity] = useState<boolean>(true)

  const [isChallengePopupOpen, setChallengePopupOpen] = useState<boolean>(false)
  const [isExitPopupOpen, setExitPopupOpen] = useState<boolean>(false)
  const [isPausePopupOpen, setPausePopupOpen] = useState<boolean>(false)
  const [isResetPopupOpen, setResetPopupOpen] = useState<boolean>(false)

  const toggleHits = () => setDisplayHits(!displayHits)
  const toggleForce = () => setDisplayForce(!displayForce)
  const toggleSpeed = () => setDisplaySpeed(!displaySpeed)
  const toggleIntensity = () => setDisplayIntensity(!displayIntensity)
  
  const toggleChallengePopup = () => setChallengePopupOpen(!isChallengePopupOpen)
  const toggleResetPopup = () => setResetPopupOpen(!isResetPopupOpen)
  const toggleExitPopup = () => setExitPopupOpen(!isExitPopupOpen)
  const togglePausePopup = () => setPausePopupOpen(!isPausePopupOpen)

  const contextValues = {
    setChallengePopupOpen,
    toggleHits,
    toggleForce,
    toggleSpeed,
    toggleIntensity,
    isChallengePopupOpen,
    toggleChallengePopup,
    isResetPopupOpen,
    setResetPopupOpen,
    toggleResetPopup,
    isExitPopupOpen,
    setExitPopupOpen,
    toggleExitPopup,
    isPausePopupOpen,
    setPausePopupOpen,
    togglePausePopup,
    displayHits,
    displayForce,
    displaySpeed,
    displayIntensity,
  }

  return (
    <ConfigContext.Provider value={contextValues}>
      {children}
    </ConfigContext.Provider>
  )
}

export const useConfig = () => useContext(ConfigContext)
