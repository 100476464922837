import { Paragraph, ParagraphS, Head1 } from '../../components/text'
import { Button, ButtonLarge, ButtonSquare } from '../../components/button'
import { ButtonEnum } from '../../interfaces/components'
// import './style.scss'

const DesignSystemPage: React.FC = () => {
  return (
    <div className="DesignSystemPage">
      <div>
        <ParagraphS>This is a small paragraph</ParagraphS>
        <ParagraphS className="light">
          This is a small light paragprah
        </ParagraphS>
        <ParagraphS className="gray">This is a small gray paragraph</ParagraphS>
        <ParagraphS className="light gray">
          This is a small gray + light paragraph
        </ParagraphS>
        <div className="DesignSystemPage__space" />
        <Paragraph>This is a normal paragraph</Paragraph>
        <Paragraph className="light">This is a light paragraph</Paragraph>
        <Paragraph className="gray">This is a gray paragraph</Paragraph>
        <Paragraph className="gray light">
          This is a gray + light paragraph
        </Paragraph>
        <div className="DesignSystemPage__space" />
        <Head1>This is a normal h1</Head1>
        <Head1 className="light">This is a light h1</Head1>
        <Head1 className="gray">This is a gray h1</Head1>
        <Head1 className="gray light">This is a gray + light h1</Head1>
      </div>
      <div>
        <ButtonSquare>
          <Paragraph>1</Paragraph>
        </ButtonSquare>
        <div className="DesignSystemPage__space" />
        <ButtonSquare modifier={ButtonEnum.secondary}>
          <Paragraph>2</Paragraph>
        </ButtonSquare>
        <div className="DesignSystemPage__space" />
        <ButtonSquare modifier={ButtonEnum.tertiary}>
          <Paragraph>3</Paragraph>
        </ButtonSquare>
        <div className="DesignSystemPage__space" />
        <Button>
          <Paragraph>Primary Btn</Paragraph>
        </Button>
        <div className="DesignSystemPage__space" />
        <Button modifier={ButtonEnum.secondary}>
          <Paragraph>Secondary Btn</Paragraph>
        </Button>
        <div className="DesignSystemPage__space" />
        <Button modifier={ButtonEnum.tertiary}>
          <Paragraph>Tertiary Btn</Paragraph>
        </Button>
        <div className="DesignSystemPage__space" />
        <ButtonLarge>
          <Head1>Primary Btn</Head1>
        </ButtonLarge>
        <div className="DesignSystemPage__space" />
        <ButtonLarge modifier={ButtonEnum.secondary}>
          <Head1>Secondary Btn</Head1>
        </ButtonLarge>
        <div className="DesignSystemPage__space" />
        <ButtonLarge modifier={ButtonEnum.tertiary}>
          <Head1>Tertiary Btn</Head1>
        </ButtonLarge>
        <div className="DesignSystemPage__space" />
      </div>
    </div>
  )
}

export default DesignSystemPage
