import { ButtonEnum, IconNames } from 'interfaces/components'
import { Config } from '../../interfaces/providers'
import PausePopup from '../pause-popup'
import ResetPopup from '../reset-popup'
import { ButtonSquare } from '../button'
import { Modal } from '../modal'
import Icon from '../icons'

type ToolbarPropsToPick =
  | 'isPausePopupOpen'
  | 'setPausePopupOpen'
  | 'togglePausePopup'
  | 'isResetPopupOpen'
  | 'toggleResetPopup'
  | 'setResetPopupOpen'

interface ToolbarConfigProps extends Pick<Config, ToolbarPropsToPick> {
  resetWorkout: () => void
}

export const ToolbarConfig = ({
  isPausePopupOpen,
  setPausePopupOpen,
  togglePausePopup,
  isResetPopupOpen,
  setResetPopupOpen,
  toggleResetPopup,
  resetWorkout,
}: ToolbarConfigProps): JSX.Element => (
  <div className="toolbar__config">
    <ButtonSquare modifier={ButtonEnum.secondary}>
      <Icon name={IconNames.pause} onClick={togglePausePopup} size={20} />
    </ButtonSquare>
    <Modal isModalOpen={isPausePopupOpen} setModalOpen={setPausePopupOpen}>
      <PausePopup closeModal={togglePausePopup} />
    </Modal>
    <ButtonSquare>
      <Icon name={IconNames.reset} onClick={toggleResetPopup} />
    </ButtonSquare>
    <Modal isModalOpen={isResetPopupOpen} setModalOpen={setResetPopupOpen}>
      <ResetPopup closeModal={toggleResetPopup} resetWorkout={resetWorkout} />
    </Modal>
  </div>
)
