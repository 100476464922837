import { MouseEvent } from 'react';

import { Head1, Paragraph } from 'components/text';
import { Button } from 'components/button';

export default function ErrorMessage({ message = '' }: { message?: string }) {
  const handleReload = (evt: MouseEvent) => {
    evt.preventDefault();
    return window.location.reload();
  }

  return (
    <div className='error'>
      <Head1>Something went wrong.</Head1>
      <Paragraph>
        {message}
      </Paragraph>
      <Button onClick={handleReload}>
        Refresh page
      </Button>
    </div>
  )
}
