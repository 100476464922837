import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'

import { ButtonEnum } from '../../interfaces/components'
import { ButtonLarge } from '../../components/button'
import { useGame } from 'providers/game-provider'

export const SetupPage: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const { setDualPlayer } = useGame()
  const [clickedBtns, setClickedBtns] = useState<boolean[]>([false, false])

  const isClicked = clickedBtns.some((bool) => !!bool)

  const handleStartGame = () => {
    if (!!isClicked) navigate('/workout')
  }

  const handleDualPlayer = (
    evt: React.BaseSyntheticEvent,
    index: number,
    dualPl: boolean
  ) => {
    evt.preventDefault()
    setClickedBtns((prev: boolean[]) => {
      return [...prev].map((_, i) => (i === index ? true : false))
    })
    setDualPlayer(dualPl)
  }

  return (
    <div className="setupPage">
      <ButtonLarge
        modifier={!!clickedBtns[0] ? ButtonEnum.secondary : ButtonEnum.primary}
        children="1 Player"
        onClick={(evt) => handleDualPlayer(evt, 0, false)}
      />
      <ButtonLarge
        modifier={!!clickedBtns[1] ? ButtonEnum.secondary : ButtonEnum.primary}
        children="2 Players"
        onClick={(evt) => handleDualPlayer(evt, 1, true)}
      />
      <ButtonLarge
        modifier={isClicked ? ButtonEnum.secondary : ButtonEnum.disabled}
        children="Go!"
        onClick={handleStartGame}
      />
    </div>
  )
}

export default SetupPage
