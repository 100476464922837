import { useState, useEffect } from 'react'
import { useQuery, useSubscription } from '@apollo/client'

import { BagProps } from 'interfaces/tablet'
import { GET_TABLETS_AND_BAGS } from 'graphql/queries'
import { SUBSCRIBE_HIT } from 'graphql/subscriptions'
import { WorkoutData, Player, PlayerHits, Hit } from 'interfaces/workout'

const apiKey: string = localStorage.getItem('NEXTROUND_GROUP_API') || ''

function filterDataByKey(data: any) {
  return data.apiKey.find((item: any) => item.key === JSON.parse(apiKey))
}

export default function useDataResources({
  activePlayer,
}: {
  activePlayer: Player
}) {
  const tabletQueryResults = useQuery(GET_TABLETS_AND_BAGS)
  const { data, error } = useSubscription<{ hit: Hit }>(SUBSCRIBE_HIT)
  const [workout, setWorkout] = useState<WorkoutData>({})
  const [loading, setLoading] = useState<boolean>(false)

  const resetWorkout = () =>
    setWorkout((prevState: WorkoutData) => {
      return Object.entries(prevState).reduce(
        (acc: WorkoutData, [id]: [string, PlayerHits]) => {
          return {
            ...acc,
            [id]: {
              player1: [],
              player2: [],
            },
          }
        },
        {}
      )
    })

  useEffect(() => {
    setLoading(true)

    if (tabletQueryResults.data && tabletQueryResults.data.apiKey) {
      //* important: currently only one tablet per group is supported
      const data = filterDataByKey(tabletQueryResults.data)?.tablet[0]

      const initBagsData: Record<string, []> | {} = (
        (data.bag || []) as BagProps[]
      ).reduce((acc: Record<string, []> | {}, curr: BagProps) => {
        const id = curr.id
        return {
          ...acc,
          [id]: {
            player1: [],
            player2: [],
          },
        }
      }, {})

      setWorkout(initBagsData)
      setLoading(false)
    }
  }, [tabletQueryResults.data])

  useEffect(() => {
    if (data && data.hit) {
      setWorkout((prev: WorkoutData) => {
        const id: string = data.hit.bag.id

        return {
          ...prev,
          [id]: {
            player1:
              activePlayer === 'player1'
                ? [...(prev[id].player1 || []), data.hit]
                : [...prev[id].player1],
            player2:
              activePlayer === 'player2'
                ? [...(prev[id].player2 || []), data.hit]
                : [...prev[id].player2],
          },
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    workout,
    resetWorkout,
    error,
    loading,
  }
}
