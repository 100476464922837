import useToggle from 'lib/hooks/useToggle'

interface ToggleProps {
  className?: string
  cb?: () => void
}

const Toggle: React.FC<ToggleProps> = ({ className = '', cb }) => {
  const { isOpen, toggle } = useToggle(true, cb)

  return (
    <div className={`${className} toggle`} onClick={toggle}>
      <div
        className={`toggle__circle toggle__circle${
          isOpen ? '--left' : '--right'
        }`}
      />
    </div>
  )
}

export default Toggle
