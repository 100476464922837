import { Button } from "components/button";
import { Head1, Head3, Paragraph } from "components/text";
import { ButtonEnum } from "interfaces/components";

interface ResetPopupProps {
  closeModal: () => void
  resetWorkout: () => void
}

export default function ResetPopup({ closeModal, resetWorkout }: ResetPopupProps) {
  return (
    <div className="resetPopup">
      <Head1>Reset workout</Head1>
      <Paragraph>Are you sure that you want to reset the workout? </Paragraph>
      <div className='resetPopup__buttons'>
        <Button modifier={ButtonEnum.primary} onClick={resetWorkout}>
          <Head3>Yes, reset</Head3>
        </Button>
        <Button modifier={ButtonEnum.tertiary} onClick={closeModal}>
          <Head3>Cancel</Head3>
        </Button>
      </div>
    </div>
  )
}

