// import { ActiveChallenge } from '../../interfaces/workout'
import { WorkoutCardProps } from 'interfaces/components'
import { useGame } from 'providers/game-provider'
import StatsCard from './stats-card'
import ChallengeCard from './challenge-card'
import withStats from 'lib/hocs/withStats'

const WorkoutCard = ({
  number,
  hitsCount,
  avgSpeed,
  totalForce,
  avgIntensity,
}: WorkoutCardProps) => {
  const { activeChallenge } = useGame()

  return !!activeChallenge ? (
    <ChallengeCard
      number={number}
      activeChallenge={activeChallenge}
      hitsCount={hitsCount}
      totalForce={totalForce}
    />
  ) : (
    <StatsCard
      number={number}
      hitsCount={hitsCount}
      avgSpeed={avgSpeed}
      totalForce={totalForce}
      avgIntensity={avgIntensity}
    />
  )
}

export default withStats(WorkoutCard)
