import { useNavigate } from 'react-router-dom'

import { ButtonEnum, ToolbarProps } from '../../interfaces/components'
import { useConfig } from '../../providers/config-provider'
import { useGame } from '../../providers/game-provider'
import { Paragraph } from '../text'
import ExitPopup from '../exit-popup'
import { Button } from '../button'
import { Modal } from '../modal'
import Timer from '../timer'
import Toggle from 'components/toggle'
import { ToolbarConfig } from './config'

const ToolbarTop = ({
  toggleActivePlayer,
  resetWorkoutData,
}: ToolbarProps): JSX.Element => {
  const navigate = useNavigate()
  const {
    isExitPopupOpen,
    setExitPopupOpen,
    toggleExitPopup,
    isPausePopupOpen,
    setPausePopupOpen,
    togglePausePopup,
    isResetPopupOpen,
    setResetPopupOpen,
    toggleResetPopup,
  } = useConfig()
  const { dualPlayer } = useGame()

  const quitWorkout = () => {
    resetWorkoutData()
    toggleExitPopup()
    navigate('/')
  }

  const resetWorkout = () => {
    resetWorkoutData()
    toggleResetPopup()
  }

  return (
    <>
      {dualPlayer && (
        <div className="toolbar__players">
          <Paragraph className="toolbar__players__title">Players</Paragraph>
          <Toggle cb={toggleActivePlayer} />
        </div>
      )}
      <Timer className="toolbar__time" />
      <ToolbarConfig
        isPausePopupOpen={isPausePopupOpen}
        setPausePopupOpen={setPausePopupOpen}
        togglePausePopup={togglePausePopup}
        isResetPopupOpen={isResetPopupOpen}
        setResetPopupOpen={setResetPopupOpen}
        toggleResetPopup={toggleResetPopup}
        resetWorkout={resetWorkout}
      />
      <Button
        className="toolbar__endTraining"
        modifier={ButtonEnum.tertiary}
        onClick={toggleExitPopup}
      >
        <Paragraph>Exit</Paragraph>
      </Button>
      <Modal isModalOpen={isExitPopupOpen} setModalOpen={setExitPopupOpen}>
        <ExitPopup quitWorkout={quitWorkout} closeModal={toggleExitPopup} />
      </Modal>
    </>
  )
}

export default ToolbarTop
