import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import DesignSystemPage from './pages/design-system'
import WorkoutActive from './pages/workout'
import SetupPage from './pages/setup'
import HomePage from './pages/home'
import ApiPage from './pages/api'

import ErrorBoundary from 'components/error/boundary'

import { ConfigProvider } from './providers/config-provider'
import { GameProvider } from './providers/game-provider'

import './styles/app.scss'
import './styles/globals.css'
import './styles/main.scss'

const apiKey = localStorage.getItem('NEXTROUND_GROUP_API')

// TODO: Add withAuth HOC
const App: React.FC = () => {
  return (
    <div className="nextround">
      <ErrorBoundary>
        <ConfigProvider>
          <GameProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={!!apiKey ? <HomePage /> : <ApiPage />} />
                <Route path="/setup" element={!!apiKey ? <SetupPage /> : <ApiPage />} />
                <Route path="/workout" element={!!apiKey ? <WorkoutActive /> : <ApiPage />} />
                <Route path="/design-system" element={<DesignSystemPage />} />
              </Routes>
            </BrowserRouter>
          </GameProvider>
        </ConfigProvider>
      </ErrorBoundary>
    </div>
  )
}

export default App
