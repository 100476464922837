import { CardsGridProps } from 'interfaces/components';
import AvgCard from 'components/workout-card/avg-card'
import WorkoutCard from 'components/workout-card'
import { PlayerHits } from 'interfaces/workout'

const CardsGrid = ({ workout, totalHits, activePlayer }: CardsGridProps) => (
  <div className="cardsGrid">
    <AvgCard hits={totalHits} />
    {Object.entries(workout)
      .map(([bagId, hits]: [string, PlayerHits], i: number) => (
        <WorkoutCard
          number={++i}
          key={bagId}
          hits={hits[activePlayer]}
        />
      ))}
  </div>
)

export default CardsGrid
