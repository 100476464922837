import { Config, Game } from '../interfaces/providers'

export const initConfigContext: Config = {
  displayHits: true,
  displayForce: true,
  displaySpeed: true,
  displayIntensity: true,
  isChallengePopupOpen: false,
  isPausePopupOpen: false,
  isExitPopupOpen: false,
  isResetPopupOpen: false,
  setExitPopupOpen: () => false,
  setPausePopupOpen: () => false,
  setChallengePopupOpen: () => false,
  setResetPopupOpen: () => false,
  toggleHits: () => {},
  toggleForce: () => {},
  toggleSpeed: () => {},
  toggleIntensity: () => {},
  toggleChallengePopup: () => {},
  toggleExitPopup: () => {},
  togglePausePopup: () => {},
  toggleResetPopup: () => {},
}

export const initGameContext: Game = {
  dualPlayer: true,
  currentCounter: 0,
  activeChallenge: null,
  setDualPlayer: () => {},
  toggleDualPlayer: () => {},
  updateCurrentCounter: () => {},
  updateActiveChallenge: () => {},
}
