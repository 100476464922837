import React, { createContext, useContext, useState } from 'react'

import { ActiveChallenge } from '../interfaces/workout'
import { Game } from '../interfaces/providers'
import { initGameContext } from '../constants/providers'

// This provider handles the workout data:
// - All exercises, hits, their time etc

const GameContext: React.Context<Game> = createContext(initGameContext)

export const GameProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [dualPlayer, setDualPlayer] = useState<boolean>(
    initGameContext.dualPlayer
  )

  const [currentCounter, setCurrentCounter] = useState<number>(
    initGameContext.currentCounter
  )

  const [activeChallenge, setActiveChallenge] = useState<ActiveChallenge>(
    initGameContext.activeChallenge
  )

  const updateCurrentCounter = (number: number) => {
    setCurrentCounter(number)
  }

  const updateActiveChallenge = (challenge: null | ActiveChallenge) => {
    setActiveChallenge(challenge)
  }

  const toggleDualPlayer = () => setDualPlayer(!dualPlayer)

  const contextValues = {
    dualPlayer,
    setDualPlayer,
    currentCounter,
    activeChallenge,
    toggleDualPlayer,
    updateCurrentCounter,
    updateActiveChallenge,
  }

  return (
    <GameContext.Provider value={contextValues}>
      {children}
    </GameContext.Provider>
  )
}

export const useGame = () => useContext(GameContext)
