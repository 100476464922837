import { useGame } from "providers/game-provider";
import { WorkoutCardProps } from "interfaces/components";
import { calcHitsAvgSpeedOver5sec } from "lib/speed";
import { calcTotalHitsForce } from "lib/utils";
import { useIntensity } from "lib/intensity";

const defaultUserWeight = 75

export default function withAvgStats(Component: React.FC<WorkoutCardProps>) {
  return (props: any) => {
    const { currentCounter } = useGame()
    const { hits } = props;
    if (!Array.isArray(hits)) throw new Error('hits must be an array');

    const totalHitsPerMinute = hits.length
    const avgSpeedPerMinute = calcHitsAvgSpeedOver5sec(hits, currentCounter)
    const totalForceInKg = Math.round(calcTotalHitsForce(hits))
    const avgIntensity = useIntensity(hits, defaultUserWeight, currentCounter)

    return (
      <Component
        {...props}
        totalHitsPerMinute={totalHitsPerMinute}
        totalForceInKg={totalForceInKg}
        avgIntensity={avgIntensity}
        avgSpeedPerMinute={avgSpeedPerMinute}
      />
    )
  }
}
