import { useState, useEffect, RefObject } from 'react'

const useOverflow = (ref: RefObject<HTMLElement>, dependencies?: any) => {
  const [overflowY, setOverflowY] = useState<boolean>(false)
  const [scrollStartY, setScrollStartY] = useState<boolean>(true)
  const [scrollEndY, setScrollEndY] = useState<boolean>(false)

  useEffect(() => {
    if (!ref?.current) return

    const isOverflowY = ref.current.scrollHeight > ref.current.clientHeight
    setOverflowY(isOverflowY)

    const currentRef = ref?.current
    const handleScroll = () => {
      if (!ref?.current) return

      const offsetBottom =
      currentRef.scrollHeight! - currentRef.clientHeight!

      if (currentRef.scrollTop >= offsetBottom && !scrollEndY) {
        setScrollEndY(true)
      } else {
        setScrollEndY(false)
      }

      if (currentRef.scrollTop === 0) {
        setScrollStartY(true)
      } else {
        setScrollStartY(false)
      }
    }

    currentRef.addEventListener('scroll', handleScroll)
    return (): void => currentRef.removeEventListener('scroll', handleScroll)
  }, [ref, dependencies, scrollEndY])

  return { overflowY, scrollStartY, scrollEndY }
}

export default useOverflow
