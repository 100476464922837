import { MouseEvent, useState } from "react";

export default function useToggle(defaultValue: boolean = false, callback?: () => void) {
  const [isOpen, setOpen] = useState(defaultValue);
  
  const toggle = (evt: MouseEvent) => {
    evt.preventDefault();
    setOpen(!isOpen)
    if (callback) callback(); 
  };

  return {
    isOpen,
    setOpen,
    toggle,
  }
}

