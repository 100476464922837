import { useRef, useState } from 'react';
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation
} from '@apollo/client'

import useDataResources from 'lib/hooks/useDataResources'
import { useConfig } from 'providers/config-provider';
import { useGame } from 'providers/game-provider'
import useOverflow from 'lib/hooks/useOverflow';
import { Hit, Player } from 'interfaces/workout'
import { PUNCH } from 'graphql/mutations'

import CardsGrid from 'components/cards-grid'
import ErrorMessage from 'components/error';
import { Button } from 'components/button'
import ToolBar from 'components/toolbar'
import Spinner from 'components/spinner';
import { Head1 } from 'components/text'

const testBagId = process.env.REACT_APP_BAG_ID || ''

type HitHandlerType = (options?: MutationFunctionOptions<
  HitHandlerType,
  OperationVariables,
  DefaultContext,
  ApolloCache<any>
> | undefined)
  => Promise<FetchResult<
    HitHandlerType,
    Record<string, Hit>,
    Record<string, Hit>
  >>

const HitMeBtn = ({ handleHit }: { handleHit: HitHandlerType }) => (
  <div className="hitBag">
    <Button onClick={() => handleHit()}>
      <h3>Hit me!</h3>
    </Button>
  </div>
)

const Workout = () => {
  const gridWrapper = useRef(null)
  const [doHit] = useMutation<HitHandlerType>(PUNCH, {
    variables: {
      bagId: testBagId,
    }
  })
  const [activePlayer, setActivePlayer] = useState<Player>('player1')
  const { workout, resetWorkout, loading, error } = useDataResources({ activePlayer })
  const { updateCurrentCounter } = useGame()
  const dependencies = (({
    displayHits,
    displayForce,
    displaySpeed,
    displayIntensity,
  }) => ({ displayHits, displayForce, displaySpeed, displayIntensity }))(
    useConfig()
  )
  const { overflowY, scrollEndY } = useOverflow(gridWrapper, dependencies)
  const scrollModifier = !scrollEndY ? '--top' : ''

  const activePlayersTotalHits = Object.values(workout)
    .flatMap(bag => bag[activePlayer])

  const toggleActivePlayer = () => {
    setActivePlayer((prev) => prev === 'player1' ? 'player2' : 'player1')
  }

  const resetWorkoutData = () => {
    setActivePlayer('player1')
    updateCurrentCounter(0)
    resetWorkout()
  }

  return (
    <div className='workout'>
      <ToolBar
        toggleActivePlayer={toggleActivePlayer}
        resetWorkoutData={resetWorkoutData}
      />
      <main
        ref={gridWrapper}
        className={`gridWrapper ${overflowY &&
          `gridWrapper--scrollable gridWrapper--scrollable${scrollModifier}`
          }`}
      >
        {!!testBagId && <HitMeBtn handleHit={doHit} />}
        {
          error ? (<ErrorMessage message={error.message} />)
            : loading ?
              (<Spinner loading={loading} color='#00c0ba' />)
              : Object.keys(workout).length === 0
                ? (
                  <Head1 className="workout__noBags">
                    No connected bags available
                  </Head1>
                )
                : (
                  <CardsGrid
                    activePlayer={activePlayer}
                    workout={workout}
                    totalHits={activePlayersTotalHits}
                  />
                )
        }
      </main>
    </div>
  )
}

export default Workout

