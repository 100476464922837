import { useCallback, useEffect } from 'react'

export default function withKeyPress(
  Component: React.ComponentType<any>,
  key: string = "Escape"
) {
  return (componentProps: any) => {
    const { setModalOpen } = componentProps

    const handleKeyPress = useCallback((e: KeyboardEvent) => {
      if (e.key === key) {
        setModalOpen(false)
      }
    }, [setModalOpen]);

    useEffect(() => {
      document.addEventListener("keydown", handleKeyPress);

      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      }
    }, [handleKeyPress])

    return (
      <Component
        {...componentProps}
      />
    )
  }
}

