import { useEffect, useState } from "react"

import { Hit } from "interfaces/workout"
import getMax2Decimal from "./utils"


const evaluateIntensity = (intensity: number = 1) => {
  const n = getMax2Decimal(intensity)
  let zone: number = 1

  switch (true) {
    case n > 5 && n < 9:
      zone = 2
      break
    case n > 9 && n < 13:
      zone = 3
      break
    case n > 13 && n < 17:
      zone = 4
      break
    case n > 17:
      zone = 5
      break
    default:
      zone = 1
      break
  }

  return zone
}

// TODO: Add Hit interface when there's actual data
const calculateTotalIntensity = (
  filteredHits: any[] = [],
  weight: number
) => {
  const intensityPerHit = filteredHits?.map((hit) => {
    return hit.force / weight
  })

  const totalIntensity = intensityPerHit.reduce((a, b) => a + b, 0)

  return totalIntensity
}

export const useIntensity = (
  hits: Hit[],
  weight: number = 75,
  timePassed: number
) => {
  const [intensity, setIntensity] = useState<number>(1)

  useEffect(() => {
    const currentTime = Date.now()
    const timeRangeForCalc = 10

    if (timePassed < timeRangeForCalc) {
      const totalIntensity = calculateTotalIntensity(hits, weight)
      const evaluatedIntensity = evaluateIntensity(totalIntensity)
      setIntensity(evaluatedIntensity)
    }

    if (timePassed >= timeRangeForCalc) {
      const filteredHits = hits?.filter((hit) => {
        const timeDifference = (currentTime - parseFloat(String(hit.punchedAt))) / 1000
        return timeDifference < timeRangeForCalc
      })

      const totalIntensity = calculateTotalIntensity(filteredHits, weight)
      const evaluatedIntensity = evaluateIntensity(totalIntensity)
      setIntensity(evaluatedIntensity)
    }

    return () => setIntensity(1)
  }, [hits, weight, timePassed, intensity])

  return isFinite(intensity) ? intensity : 1
}
