import { gql } from '@apollo/client'

export const GET_TABLETS_AND_BAGS = gql`
  query {
    apiKey {
      key
      tablet {
        reference
        bag {
          id
          reference
          name
        }
      }
    }
  }
`
