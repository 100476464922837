import { ButtonEnum } from "interfaces/components";
import { Head1, Head3, Paragraph } from "../text";
import { Button } from "../button";

interface PausePopupProps {
  closeModal: () => void
}

export default function PausePopup({ closeModal }: PausePopupProps) {
  return (
    <div className="pausePopup">
      <Head1> Pause workout</Head1>
      <Paragraph>
        You’ve paused the workout.
        Click on the button below whenever you wish to continue.
      </Paragraph>
      <Button modifier={ButtonEnum.primary} onClick={closeModal}>
        <Head3>Continue</Head3>
      </Button>
    </div>
  )
}

