import { useState } from 'react'

import { Button } from '../../components/button'
import './style.scss'

export interface ApiPageProps {
  boolean?: boolean
}

export default function ApiPage(props: ApiPageProps): JSX.Element {
  const [key, setKey] = useState<string>('')

  const saveApiKey = () => {
    if (key) {
      window.localStorage.setItem('NEXTROUND_GROUP_API', JSON.stringify(key))
      window.location.assign('/')
      return false
    }
  }

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      saveApiKey()
    }
  }

  return (
    <div className='apipage'>
      Enter the API key:
      <form>
        <input
          type='text'
          placeholder={'API Key'}
          value={key}
          onKeyPress={handleKeyPress}
          onChange={ev => setKey(ev.target.value)}
          autoCapitalize='none'
        />
      </form>
      <Button onClick={saveApiKey}>Save & Refresh</Button>
    </div>
  )
}
