import { Hit } from 'interfaces/workout'

// Convert float to 2 decimal
const getMax2Decimal = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100

export default getMax2Decimal

const getTwoDigitNum = (num: number) => ('0' + num).slice(-2)

const getThreeDigitNum = (num: number) => ('0' + num).slice(-4)

export const getMinTwoDigitNum = (num: number) => {
  const numLength = num.toString().length
  return numLength <= 2 ? getTwoDigitNum(num) : getThreeDigitNum(num)
}

export const getDurationHMS = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds - hours * 3600 - minutes * 60

  return { hours, minutes, seconds }
}

export const formatDurationHMS = (totalSeconds: number) => {
  if (!totalSeconds) return '00:00:00'

  const { hours, minutes, seconds } = getDurationHMS(totalSeconds)

  return [
    `${getMinTwoDigitNum(hours)}:`,
    `${getMinTwoDigitNum(minutes)}:`,
    `${getMinTwoDigitNum(seconds)}`,
  ].join('')
}

export const calcTotalHitsForce = (hits: Hit[] = []) => {
  if (!Array.isArray(hits) || hits.length === 0) return 0
  return hits.reduce((acc, hit) => acc + hit.force, 0)
}
