import { useRef } from "react";

import withKeyPress from "../../lib/hocs/withKeyPress";
import useOnClickOutside from "../../lib/hooks/useOnClickOutside";
import NextRoundIcon from '../icons/next-round'
import CloseIcon from '../icons/close'
import { ModalProps } from "interfaces/components";


export function Modal({ isModalOpen, setModalOpen, children }: ModalProps) {
  const modalRef = useRef(null);
  const toggleModal = () => setModalOpen(!isModalOpen);
  const closeModal = () => {
    if (isModalOpen) setModalOpen(false);
    return
  }

  useOnClickOutside(modalRef, closeModal);

  return (
    <div className={`modal modal-${isModalOpen ? 'open' : 'closed'}`}>
      <div ref={modalRef} className='modal__wrapper'>
        <div className='modal__header'>
          <NextRoundIcon />
          <span onClick={toggleModal}>
            <CloseIcon />
          </span>
        </div>
        <div className='modal__content'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default withKeyPress(Modal)
