import { useState } from 'react'
import Heartbeat from 'react-heartbeat'

import { useGame } from '../../providers/game-provider'
import { Head1, Paragraph } from '../text'
import { formatDurationHMS } from '../../lib/utils'
import { useConfig } from 'providers/config-provider'

interface TimerProps {
  className: string
}

const Timer: React.FC<TimerProps> = ({ className }) => {
  const { currentCounter, updateCurrentCounter } = useGame()
  const { isPausePopupOpen } = useConfig()
  const [date, setDate] = useState<Date>(new Date())

  const updateClock = () => {
    setDate(new Date())
  }

  const countUp = () => updateCurrentCounter(currentCounter + 1)

  const count = () => {
    updateClock()
    !isPausePopupOpen && countUp()
  }

  return (
    <div className={className}>
      <Heartbeat heartbeatFunction={count} heartbeatInterval={1000} />
      <Head1>{formatDurationHMS(currentCounter)}</Head1>
      <Paragraph className="gray light">{date.toLocaleTimeString()}</Paragraph>
    </div>
  )
}

export default Timer
