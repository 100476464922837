import React from "react";

import ErrorMessage from "./index";

type State = {
  error: Error | null;
  hasError: boolean;
};
  
class ErrorBoundary<T> extends React.Component<T, State> {
  constructor(props: T) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorMessage message={this.state.error?.message}/>
    }

    return this.props.children;
  }
}

export default ErrorBoundary

