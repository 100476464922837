import { TextProps } from '../../interfaces/components'
import './style.scss'

// <Paragraph /> size will be adjusted depending in <Grid />, depending on number of cards inside the grid
export const Paragraph = ({ children, className = '' }: TextProps) => {
  return <p className={`paragraph ${className}`}>{children}</p>
}

export const ParagraphS = ({ children, className = '' }: TextProps) => {
  return <p className={`paragraph-s ${className}`}>{children}</p>
}

export const Head1 = ({ children, className = '' }: TextProps) => {
  return <h1 className={`head-1 ${className}`}>{children}</h1>
}

export const Head2 = ({ children, className = '' }: TextProps) => {
  return <h1 className={`head-2 ${className}`}>{children}</h1>
}

export const Head3 = ({ children, className = '' }: TextProps) => {
  return <h1 className={`head-3 ${className}`}>{children}</h1>
}
