import { ChallengeCardProps } from 'interfaces/components'
import { Head1 } from 'components/text'

const ChallengeCard: React.FC<ChallengeCardProps> = ({
  number,
  activeChallenge,
  hitsCount,
  totalForce,
}) => {
  const challengeStat = activeChallenge === 'Hits' ? hitsCount : totalForce

  // temp!
  const tempSuccessBool: boolean = true

  return (
    <div
      className={`workoutCard workoutCard${
        !!tempSuccessBool ? '--success' : '--failed'
      }`}
    >
      <span className="workoutCard__id">{number}</span>
      <div className={`workoutCard__stats workoutCard__stats`}>
        <span>
          <Head1 className="workoutCard__stats__challengeStat">
            {activeChallenge}
          </Head1>
          <Head1
            className={`workoutCard__stats__challengeStat workoutCard__stats__challengeStat${
              !!tempSuccessBool ? '--success' : '--failed'
            }`}
          >
            {challengeStat}
          </Head1>
        </span>
      </div>
    </div>
  )
}

export default ChallengeCard
