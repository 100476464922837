import { ButtonProps, ButtonEnum } from '../../interfaces/components'
import './style.scss'

export const Button: React.FC<ButtonProps> = ({
  className = '',
  modifier = ButtonEnum.primary,
  children,
  onClick,
}) => {
  return (
    <button
      type='button'
      onClick={onClick}
      className={`button button--standard ${modifier} ${className}`}
    >
      {children}
    </button>
  )
}

export const ButtonLarge: React.FC<ButtonProps> = ({
  className = '',
  modifier = ButtonEnum.primary,
  children,
  onClick
}) => {
  return (
    <button
      type='button'
      onClick={onClick}
      className={`button button--large ${modifier} ${className}`}
    >
      {children}
    </button>
  )
}

export const ButtonSquare: React.FC<ButtonProps> = ({
  className = '',
  modifier = ButtonEnum.primary,
  children,
  onClick,
}) => {
  return (
    <button
      type='button'
      onClick={onClick}
      className={`button button--square ${modifier} ${className}`}
    >
      {children}
    </button>
  )
}