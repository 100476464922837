import { Paragraph, ParagraphS } from 'components/text'
import { useConfig } from 'providers/config-provider'
import { AvgCardProps } from 'interfaces/components'
import withAvgStats from 'lib/hocs/withAvgStats'

const AvgCard: React.FC<AvgCardProps> = ({
  totalHitsPerMinute,
  avgSpeedPerMinute,
  totalForceInKg,
  avgIntensity,
}) => {
  const { displayHits, displaySpeed, displayForce, displayIntensity } =
    useConfig()

  const numOfFilters = [
    displayHits,
    displaySpeed,
    displayForce,
    displayIntensity,
  ].filter((bool) => !!bool).length

  const isSingleStat = numOfFilters === 1

  return (
    <div className="workoutCard" id="avgCard">
      <span className="workoutCard__id" />
      <div
        className={`workoutCard__stats ${
          isSingleStat && 'workoutCard__stats--singleStat'
        }`}
      >
        {displayHits && (
          <span>
            <span>
              <Paragraph>Total hits</Paragraph>
            </span>
            <p>{totalHitsPerMinute}</p>
          </span>
        )}
        {displayForce && (
          <span>
            <span>
              <Paragraph>Total force </Paragraph>
              <ParagraphS>kg</ParagraphS>
            </span>
            <p>{totalForceInKg}</p>
          </span>
        )}
        {displaySpeed && (
          <span>
            <span>
              <Paragraph>Avg. speed </Paragraph>
              <ParagraphS>min</ParagraphS>
            </span>
            <p>{avgSpeedPerMinute}</p>
          </span>
        )}
        {displayIntensity && (
          <span>
            <span>
              <Paragraph>Avg. intensity</Paragraph>
            </span>
            <p>{avgIntensity}</p>
          </span>
        )}
      </div>
    </div>
  )
}

export default withAvgStats(AvgCard)
