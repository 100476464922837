import { useConfig } from '../../providers/config-provider'
import ToolBarFilters from './filters'

const ToolbarBot: React.FC = () => {
  const {
    toggleHits,
    toggleForce,
    toggleSpeed,
    toggleIntensity,
    displayHits,
    displayForce,
    displaySpeed,
    displayIntensity,
  } = useConfig()

  return (
    <>
      <ToolBarFilters
        displayHits={displayHits}
        toggleHits={toggleHits}
        displayForce={displayForce}
        toggleForce={toggleForce}
        displaySpeed={displaySpeed}
        toggleSpeed={toggleSpeed}
        displayIntensity={displayIntensity}
        toggleIntensity={toggleIntensity}
      />
    </>
  )
}

export default ToolbarBot
