import { WorkoutCardProps } from 'interfaces/components'
import { useConfig } from 'providers/config-provider'

const StatsCard: React.FC<WorkoutCardProps> = ({
  number,
  hitsCount,
  avgSpeed,
  totalForce,
  avgIntensity,
}) => {
  const { displayHits, displaySpeed, displayForce, displayIntensity } =
    useConfig()

  const numOfFilters = [
    displayHits,
    displaySpeed,
    displayForce,
    displayIntensity,
  ].filter((bool) => !!bool).length

  const isSingleStat = numOfFilters === 1

  return (
    <div className="workoutCard">
      <span className="workoutCard__id">{number}</span>
      <div
        className={`workoutCard__stats ${
          isSingleStat && 'workoutCard__stats--singleStat'
        }`}
      >
        {displayHits && (
          <span>
            <p>Hits</p>
            <p>{hitsCount}</p>
          </span>
        )}
        {displayForce && (
          <span>
            <p>Force</p>
            <p>{totalForce}</p>
          </span>
        )}
        {displaySpeed && (
          <span>
            <p>Speed</p>
            <p>{avgSpeed}</p>
          </span>
        )}
        {displayIntensity && (
          <span>
            <p>Intensity</p>
            <p>{avgIntensity}</p>
          </span>
        )}
      </div>
    </div>
  )
}

export default StatsCard
