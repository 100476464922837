import { IconNames, IconProps } from '../../interfaces/components'

import challenge from '../../icons/challenge.svg'
import pause from '../../icons/pause.svg'
import reset from '../../icons/reset.svg'

const iconList: { [key in IconNames]: string } = {
  challenge,
  pause,
  reset,
}

const Icon: React.FC<IconProps> = ({ name, onClick, size }) => {
  return (
    <div
      onClick={onClick}
      className="icon"
      style={{ height: size && size, width: size && size }}
    >
      <img alt="icon" src={iconList[name]} />
    </div>
  )
}

export default Icon
