import { gql } from '@apollo/client'

export const SUBSCRIBE_HIT = gql`
  subscription hit {
    hit {
      force
      punchedAt
      bag {
        id
      }
    }
  }
`
