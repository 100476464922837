import { useNavigate } from "react-router-dom";

import { ButtonLarge } from '../../components/button';
import { ButtonEnum } from '../../interfaces/components';

const HomePage = (): JSX.Element => {
  const navigate = useNavigate();
  const handleClick = () => navigate('/setup');
  
  return (
    <div className='homePage' >
      <ButtonLarge
        modifier={ButtonEnum.primary}
        children="Start new group session"
        onClick={handleClick}
        className='homePage__startBtn'
      />
    </div>
  )
}

export default HomePage
