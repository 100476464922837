import { ToolbarProps } from 'interfaces/components'
import ToolbarTop from './top'
import ToolbarBot from './bottom'
import './style.scss'

export const Toolbar = ({
  toggleActivePlayer,
  resetWorkoutData
}: ToolbarProps): JSX.Element => {
  return (
    <div className="toolbar">
      <ToolbarTop
        toggleActivePlayer={toggleActivePlayer}
        resetWorkoutData={resetWorkoutData}
      />
      <ToolbarBot />
    </div>
  )
}

export default Toolbar
