import { Button } from 'components/button'
import { Head1, Head3, Paragraph } from 'components/text'
import { ButtonEnum } from 'interfaces/components'

interface ExitPopupProps {
  closeModal: () => void
  quitWorkout: () => void
}

export default function ExitPopup({ closeModal, quitWorkout }: ExitPopupProps) {
  return (
    <div className="exitPopup">
      <Head1> Quit workout</Head1>
      <Paragraph>Are you sure that you want to quit the workout? </Paragraph>
      <div className="exitPopup__buttons">
        <Button modifier={ButtonEnum.primary} onClick={quitWorkout}>
          <Head3>Yes, quit</Head3>
        </Button>
        <Button modifier={ButtonEnum.tertiary} onClick={closeModal}>
          <Head3>No, don’t quit</Head3>
        </Button>
      </div>
    </div>
  )
}
