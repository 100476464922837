import { Config } from '../../interfaces/providers'
import { ButtonEnum } from 'interfaces/components'
import { Paragraph } from 'components/text'
import { Button } from 'components/button'

type ToolbarFiltersPropsToPick =
  | 'toggleSpeed'
  | 'toggleIntensity'
  | 'toggleHits'
  | 'toggleForce'
  | 'displaySpeed'
  | 'displayIntensity'
  | 'displayHits'
  | 'displayForce'

type getModifierType = (display: boolean) => ButtonEnum
type ToolbarFiltersProps = Pick<Config, ToolbarFiltersPropsToPick>

export default function ToolBarFilters({
  displayHits,
  toggleHits,
  displayForce,
  toggleForce,
  displaySpeed,
  toggleSpeed,
  displayIntensity,
  toggleIntensity,
}: ToolbarFiltersProps): JSX.Element {
  const numOfFilters = [
    displayHits,
    displaySpeed,
    displayForce,
    displayIntensity,
  ].filter((bool) => !!bool).length

  const isSingleStat = numOfFilters === 1

  const toggleStat = (cb: () => void, displayStat: boolean) => () => {
    if (!!isSingleStat && !!displayStat) return
    cb()
  }

  const getModifier: getModifierType = (display: boolean) => {
    return !!display ? ButtonEnum.secondary : ButtonEnum.primary
  }

  return (
    <>
      <Button
        className="toolbar__hits"
        modifier={getModifier(displayHits)}
        onClick={toggleStat(toggleHits, displayHits)}
      >
        <Paragraph>Hits</Paragraph>
      </Button>
      <Button
        className="toolbar__force"
        modifier={getModifier(displayForce)}
        onClick={toggleStat(toggleForce, displayForce)}
      >
        <Paragraph>Force</Paragraph>
      </Button>
      <Button
        className="toolbar__speed"
        modifier={getModifier(displaySpeed)}
        onClick={toggleStat(toggleSpeed, displaySpeed)}
      >
        <Paragraph>Speed</Paragraph>
      </Button>
      <Button
        className="toolbar__intensity"
        modifier={getModifier(displayIntensity)}
        onClick={toggleStat(toggleIntensity, displayIntensity)}
      >
        <Paragraph>Intensity</Paragraph>
      </Button>
    </>
  )
}
